import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from './user.reducer';

export const selectUserState = createFeatureSelector<fromUser.State>(fromUser.usersFeatureKey);

export const selectAll = createSelector(selectUserState, state => {
  return fromUser.selectAll(state)
});

export const selectPatientsLastPage = createSelector(selectUserState, state => {
  return state.patients.lastPage;
});

export const selectCoachesLastPage = createSelector(selectUserState, state => {
  return state.coaches.lastPage;
});

export const selectNursesLastPage = createSelector(selectUserState, state => {
  return state.nurses.lastPage;
});

export const selectDoctorsLastPage = createSelector(selectUserState, state => {
  return state.doctors.lastPage;
});

export const selectUnassignedLastPage = createSelector(selectUserState, state => {
  return state.unassigned.lastPage;
});

export const selectLoadedAllPatients = createSelector(selectUserState, state => {
  return state.patients.loadedAll;
});

export const selectLoadedAllCoaches = createSelector(selectUserState, state => {
  return state.coaches.loadedAll;
});

export const selectLoadedAllNurses = createSelector(selectUserState, state => {
  return state.nurses.loadedAll;
});

export const selectLoadedAllDoctors = createSelector(selectUserState, state => {
  return state.doctors.loadedAll;
});

export const selectLoadedAllUnassigned = createSelector(selectUserState, state => {
  return state.unassigned.loadedAll;
});

export const selectUser = createSelector(selectAll, (users, props: {id: number}) => {
  return users.find(user => user.id === props.id);
});

export const selectUsers = createSelector(selectAll, (users, props: {ids: number[]}) => {
  return users.filter(user => props.ids.includes(user.id));
});

export const selectPatients = createSelector(selectAll, users => users.filter(user => user.roleNames && user.roleNames.includes('Patient')));

export const selectCoaches = createSelector(selectAll, users => users.filter(user => user.roleNames && user.roleNames.includes('Coach')));

export const selectNurses = createSelector(selectAll, users => users.filter(user => user.roleNames && user.roleNames.includes('Nurse')));

export const selectDoctors = createSelector(selectAll, users => users.filter(user => user.roleNames && user.roleNames.includes('Doctor')));

export const selectUnassigned = createSelector(selectAll, users => users.filter(user => user.roleNames && user.roleNames.length === 0));
