export enum Roles {
  coach = 1,
  nurse = 2,
  doctor = 3
}

export enum RoleControls {
  Coach = 'coaches',
  Nurse = 'nurses',
  Doctor = 'doctors'
}
