export const environment = {
  production: true,
  apiConstants: {
    AZURE_B2C_AUTHORITY: 'https://jhhusprod.b2clogin.com/tfp/jhhusprod.onmicrosoft.com/B2C_1A_SignUpOrSignInWithPhoneOrEmail',
    AZURE_B2C_CLIENT_ID: 'fc5b7763-4c5f-4f66-ba28-2c7a8f02f3f5',
    AZURE_B2C_SCOPE: 'https://jhhusprod.onmicrosoft.com/apiimplicit/user_impersonation',
    AZURE_B2C_RESETPASSWORD: 'undefined',
    GATEWAY_BASE_URL: 'https://gateway.usa-prod.all.health',
    USER_V2_API_URL: '/user/api/v0.2',
    HEALTHCLOUD_API_URL: '/healthcloud/api/v0.1',
    CUSTOMER_TENANT: '',
    DEMO: false,
  }
};
