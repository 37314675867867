import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {environment} from '../../../environments/environment';
import {forkJoin, Observable, of} from 'rxjs';
import {User} from '../models/user.model';
import {catchError, map, switchMap} from 'rxjs/operators';
import {UserPermissions} from '../../utils/enums/user-permissions.enum';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {

  }

  assignPatientsToUsers(userIds: number[], usersIdsToAssign: number[]){
    return forkJoin(userIds.map(id => this.assignPatientsToUser(id, usersIdsToAssign)));
  }

  assignCTMsToUsers(userIds: number[], usersIdsToAssign: number[]){
    return forkJoin(userIds.map(id => this.assignCTMsToUser(id, usersIdsToAssign)));
  }

  loadPatients(lastPage: number): Observable<User[]>{
    return this.http.get<{uid: number}[]>( // TODO: create and use factory
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.HEALTHCLOUD_API_URL}/care_team/patients/all/${lastPage+1}`
    ).pipe(
      switchMap(patients => this.getUserByIds(patients.map(patient => patient.uid)).pipe(
          map(users => users.map(user => {
            user.roleNames = ['Patient'];
            return user;
          }))
        )
      )
    );
  }

  loadCoaches(lastPage: number): Observable<User[]>{
    return this.getUsersByRole(1, lastPage)
      .pipe(
        map(users => users.map(user => {
          user.roleNames = ['Coach'];
          return user;
        }))
      )
  }

  loadNurses(lastPage: number): Observable<User[]>{
    return this.getUsersByRole(2, lastPage)
      .pipe(
        map(users => users.map(user => {
          user.roleNames = ['Nurse'];
          return user;
        }))
      )
  }

  loadDoctors(lastPage: number): Observable<User[]>{
    return this.getUsersByRole(3, lastPage)
      .pipe(
        map(users => users.map(user => {
          user.roleNames = ['Doctor'];
          return user;
        }))
      )
  }

  loadUnassignedCTMs(lastPage: number) {
    return this.http.get<{uid: number}[]>( // TODO: create and use factory
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.HEALTHCLOUD_API_URL}/roles/unassigned_ctms/${lastPage + 1}`
    ).pipe(
      switchMap(usersByRole => this.getUserByIds(usersByRole.map(patient => patient.uid)))
    )
  }

  getLoginUser(): Observable<User>{
    return this.http.get<User>(
      `${environment.apiConstants.GATEWAY_BASE_URL}/user/api/v0.1/users/@me`,
    ).pipe(
      map(user => user)
    );
  }

  getUser(id: number): Observable<User>{
    return this.http.get<User>(
      `${environment.apiConstants.GATEWAY_BASE_URL}/user/api/v0.1/users/${id}`,
    ).pipe(
      map(user => {
        user.roleNames = [];
        return user;
      }));
  }

  getUserByIds(ids: number[]): Observable<User[]>{
    if(!ids || ids.length === 0){
      return of([]);
    }
    let params = new HttpParams();
    ids.forEach(id => {
      params = params.append('uid', id.toString(10))
    });
    return this.http.get<User[]>( // TODO: create and use factory
      `${environment.apiConstants.GATEWAY_BASE_URL}/user/api/v0.1/users`, {params}
    ).pipe(map(users => users.map(user => ({...user, roleNames: []}))))
  }

  getAssignedUsers(userId: number): Observable<{assignedPatients: number[], assignedCTMs: number[]}>{
    return forkJoin({
      assignedPatients: this.getAssignedPatients(userId),
      assignedCTMs: this.getAssignedCTMs(userId).pipe(
        catchError(() => of([])) // if user is not CTM this endpoint will fail
      )
    });
  }

  removeUser(userId: number) {
    return this.http.delete(
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.HEALTHCLOUD_API_URL}/users/${userId}`)
  }

  // Roles management

  addRoleToUsers(userIds: number[], roleId: number): Observable<'Coach' | 'Nurse' | 'Doctor'>{
    return forkJoin(userIds.map(id => this.addRoleToUser(id, roleId))).pipe(map(roles => roles[0]));
  }

  addRoleToUser(userId: number, roleId: number): Observable<'Coach' | 'Nurse' | 'Doctor'>{
    const rolesMap = {
      1: 'Coach',
      2: 'Nurse',
      3: 'Doctor'
    };
    return this.http.post(
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.HEALTHCLOUD_API_URL}/roles/${roleId}/users/${userId}`, {})
      .pipe(
        map(() => {
          return rolesMap[roleId];
        })
      );
  }

  removeRoleFromUser(userId: number, roleId: number): Observable<void>{
    const rolesMap = {
      1: 'Coach',
      2: 'Nurse',
      3: 'Doctor'
    };
    return this.http.delete(
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.HEALTHCLOUD_API_URL}/roles/${roleId}/users/${userId}`)
      .pipe(
        map(() => {
          return rolesMap[roleId];
        })
      );
  }

  //Permissions management

  addPermissionToUser(userId: number, permission: UserPermissions){
    return this.http.post<void>(
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.HEALTHCLOUD_API_URL}/permissions/${permission}/users/${userId}`, {});
  }

  removePermissionFromUser(userId: number, permission: UserPermissions){
    return this.http.delete<void>(
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.HEALTHCLOUD_API_URL}/permissions/${permission}/users/${userId}`);
  }

  private getUsersByRole(roleId: number, lastPage: number): Observable<User[]>{
    return this.http.get<{uid: number}[]>( // TODO: create and use factory
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.HEALTHCLOUD_API_URL}/roles/${roleId}/users/${lastPage + 1}`
    ).pipe(
      switchMap(usersByRole => this.getUserByIds(usersByRole.map(patient => patient.uid)))
    )
  }

  private assignPatientsToUser(userId: number, usersIdsToAssign: number[]): Observable<void> {
    return this.http.post<void>(
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.HEALTHCLOUD_API_URL}/manage/assigned_patients/${userId}`,
      {
        patients: usersIdsToAssign
      }
    )
  }

  private assignCTMsToUser(userId: number, usersIdsToAssign: number[]): Observable<void> {
    return this.http.post<void>(
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.HEALTHCLOUD_API_URL}/manage/associated_ctms/${userId}`,
      {
        ctms: usersIdsToAssign
      }
    )
  }

  private getAssignedPatients(userId: number): Observable<number[]>{
    return  this.http.get<{uid: number}[]>(
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.HEALTHCLOUD_API_URL}/manage/assigned_patients/${userId}`)
      .pipe(map(info => info.map(item => item.uid)));
  }

  private getAssignedCTMs(userId: number): Observable<number[]>{
    return  this.http.get<{user_id: number}[]>(
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.HEALTHCLOUD_API_URL}/manage/associated_ctms/${userId}`)
      .pipe(map(info => info.map(item => item.user_id)));
  }
}
